div.center {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :not(style) {
      margin: 8px 0;
      width: min(60ch, 80vw);
    }

    .hidden {
      display: none;
    }

    button {
      min-width: 0;
    }
  }
}