header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;

  nav {
    display: flex;

    a, span {
      text-decoration: none;
      color: black;
      padding: 0.75rem 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      min-width: 120px;
      font-family: Raleway, system-ui, -apple-system, BlinkMacSystemFont;
      font-weight: 200;
      transition: 0.75s ease;

      &:hover {
        cursor: pointer;
        background-color: #eeeeee;
      }
    }

    span .MuiListItemText-root {
      text-align: center;

      span {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
        font-family: "Raleway", system-ui, -apple-system, BlinkMacSystemFont;
        transition: 0s;

        &:hover {
          background-color: inherit;
        }
      }
    }

    img.logo:hover {
      cursor: pointer;
    }

    .nav-overlay {
      a, span {
        width: 100%;
      }

      span svg {
        position: absolute;
        right: 4px;
        transform: rotate(-90deg);
        transition: all .3s cubic-bezier(0.5, -0.6, 0.19, 1);

        &.expanded {
          transform: rotate(0);
        }
      }
    }
  }
}