div.center {
  form.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :not(style) {
      margin: 8px 0;
      width: 30ch;
    }

    .hidden {
      display: none;
    }
  }
}