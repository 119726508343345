* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 50vw;
  z-index: 2;
  background-position: center;
  background-size: cover;
}

main {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 2rem 2rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 200;
    font-family: "Raleway", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
    color: #636363;
  }

  h1 {
    border-bottom: 0.5px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 3rem;
  }

  h2 {
    margin: 1rem 0.5rem 0.5rem 0.5rem;
  }

  h3 {
    margin: 0.7rem 0.5rem 0.5rem 0.5rem;
  }

  h4, h5, h6 {
    margin: 0.5rem;
  }

  p {
    color: #878787;
    margin: 0.8rem 0.5rem;
    line-height: 1.5;
  }

  ul {
    margin: 0.3rem 1.5rem;
    color: #878787;

    &.style-none {
      list-style: none;
      margin-left: 0.5rem;
      padding-left: 0;
    }

    li {
      line-height: 1.5rem;
    }
  }

  ol {
    margin: 0.3rem 1.7rem;
    color: #878787;

    li {
      line-height: 1.5rem;
    }
  }

  a {
    text-decoration: underline;
    color: #878787;
  }

  img {
    display: block;
    max-height: 500px;
    max-width: 70%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
  }

  pre {
    margin: 0.3rem 0.5rem;
  }

  &.center {
    width: 70vw;
    transform: translateX(15vw);
  }
}

div.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
  min-width: min(70vw, 300px);
  padding: 1.5rem;

  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont;

  button {
    min-width: 15ch;

    &.MuiIconButton-root {
      min-width: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    padding: 6px 8px;
  }

  .dashboard-item {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 1rem;
    margin: 0.5rem 0;
  }

  .MuiAlert-root {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }

  .MuiAlert-message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hidden {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  aside {
    display: none;
  }

  main.center {
    width: 100vw;
    transform: none;
  }

  main {
    img {
      max-width: 100%;
    }
  }
}

div.indent {
  text-indent: 1em;
}

@media print {
  header {
    display: none;
  }

  main.center {
    width: 100vw;
    transform: none;
  }
}