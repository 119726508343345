header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
}
header nav {
  display: flex;
}
header nav a, header nav span {
  text-decoration: none;
  color: black;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  min-width: 120px;
  font-family: Raleway, system-ui, -apple-system, BlinkMacSystemFont;
  font-weight: 200;
  transition: 0.75s ease;
}
header nav a:hover, header nav span:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
header nav span .MuiListItemText-root {
  text-align: center;
}
header nav span .MuiListItemText-root span {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  font-family: "Raleway", system-ui, -apple-system, BlinkMacSystemFont;
  transition: 0s;
}
header nav span .MuiListItemText-root span:hover {
  background-color: inherit;
}
header nav img.logo:hover {
  cursor: pointer;
}
header nav .nav-overlay a, header nav .nav-overlay span {
  width: 100%;
}
header nav .nav-overlay span svg {
  position: absolute;
  right: 4px;
  transform: rotate(-90deg);
  transition: all 0.3s cubic-bezier(0.5, -0.6, 0.19, 1);
}
header nav .nav-overlay span svg.expanded {
  transform: rotate(0);
}

